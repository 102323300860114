import {
  LEAGUE_MODULE_NAMES,
  Environment,
  LeagueConfig,
} from '@leagueplatform/core';
import { archetypeLightTheme } from '@leagueplatform/genesis-core';
import EN_STRING_OVERRIDES from './locales/en.json';
import ES_STRING_OVERRIDES from './locales/es.json';
import TL_STRING_OVERRIDES from './locales/tl.json';
import ZH_STRING_OVERRIDES from './locales/zh.json';
import VI_STRING_OVERRIDES from './locales/vi.json';

export const routesConfig = {
  [LEAGUE_MODULE_NAMES.assessment]: '/member/health-profile/questions',
  [LEAGUE_MODULE_NAMES.healthProfile]: '/member/health-profile',
  [LEAGUE_MODULE_NAMES.healthNeedPages]: '/health-need',
  [LEAGUE_MODULE_NAMES.onboarding]: '/onboarding',
};

const {
  VITE_GA_PROPERTY_ID,
  VITE_SEGEMENT_KEY,
  VITE_WS_API_URL,
  VITE_API_URL,
  VITE_LEGACY_REST_API_URL,
  VITE_CONTENT_URL,
  VITE_AUTH0_DOMAIN,
  VITE_AUTH0_CLIENT_ID,
  VITE_APP_ENV,
} = import.meta.env;

const authConfig = {
  domain: VITE_AUTH0_DOMAIN!,
  client_id: VITE_AUTH0_CLIENT_ID!,
  redirect_uri: window.location.origin,
};

const analyticsConfig = {
  GAPropertyId: VITE_GA_PROPERTY_ID,
  segmentKey: VITE_SEGEMENT_KEY,
};

const apiConfig = {
  wsUrl: VITE_WS_API_URL!,
  url: VITE_API_URL!,
  legacyRestApi: VITE_LEGACY_REST_API_URL!,
};

const appConfig = {
  contentUrl: VITE_CONTENT_URL!,
  appEnvironment: VITE_APP_ENV as Environment,
};

const customMethodsConfig = {
  handleLink: async (url: string) => {
    await window.open(url, '_blank', 'noreferrer');
  },
};

export const config: LeagueConfig = {
  core: {
    tenantId: 'league',
    appId: 'scfhp-web',
    auth: { clientOptions: authConfig, idleSessionLifetimeHours: 48 },
    analytics: analyticsConfig,
    api: apiConfig,
    ...appConfig,
    customMethods: customMethodsConfig,
    routing: { isUsingLeagueRouting: true },
    ui: {
      theme: archetypeLightTheme,
    },
    i18n: {
      stringOverrides: {
        default: EN_STRING_OVERRIDES,
        en: EN_STRING_OVERRIDES,
        es: ES_STRING_OVERRIDES,
        zh: ZH_STRING_OVERRIDES,
        vi: VI_STRING_OVERRIDES,
        tlg: TL_STRING_OVERRIDES,
      },
    },
  },
};
