/* eslint-disable import/no-extraneous-dependencies */
import { Route, Switch } from '@leagueplatform/routing';

import { AuthViewLogin, AuthViewLogout } from 'pages/auth/Auth.pages';

import {
  LandingPage,
  CareCollectionsRoutes,
  CareDiscoveryRoutes,
  HealthJourneyRoutes,
  HealthNeedPages,
  HealthProfileRoutes,
  AboutMeRoutes,
  Home,
  OnboardingRoutesWithProvider,
  WalletRoutes,
} from 'components/LazyRoutes';

import { getModulePath, LEAGUE_MODULE_NAMES } from '@leagueplatform/core';

import ErrorPage from 'pages/Error404';
import { AuthorizedRoutes } from './authorized-routes/AuthorizedRoutes';

function AppRoutes() {
  return (
    <Switch>
      <Route path="/sign-in" component={AuthViewLogin} />
      <Route path="/sign-out" component={AuthViewLogout} />
      <Route path="/" exact component={LandingPage} />
      <AuthorizedRoutes>
        <Switch>
          <Route path="/home" component={Home} exact key="home" />
          <Route
            path={[
              getModulePath(LEAGUE_MODULE_NAMES.healthJourney),
              getModulePath(LEAGUE_MODULE_NAMES.healthPrograms),
              getModulePath(LEAGUE_MODULE_NAMES.healthProgress),
              getModulePath(LEAGUE_MODULE_NAMES.healthJourneyHistory),
            ]}
            component={HealthJourneyRoutes}
            key="health-journey"
          />
          <Route
            path={getModulePath(LEAGUE_MODULE_NAMES.careCollections)}
            component={CareCollectionsRoutes}
            key="care-collections"
          />
          <Route
            path={getModulePath(LEAGUE_MODULE_NAMES.careDiscovery)}
            component={CareDiscoveryRoutes}
            key="care-discovery"
          />
          <Route
            path={getModulePath(LEAGUE_MODULE_NAMES.healthNeedPages)}
            component={HealthNeedPages}
            key="health-needs"
          />
          <Route
            path={getModulePath(LEAGUE_MODULE_NAMES.wallet)}
            component={WalletRoutes}
            key="wallet"
          />
          <Route
            path={getModulePath(LEAGUE_MODULE_NAMES.healthProfile)}
            component={HealthProfileRoutes}
            key="health-profile"
          />
          <Route
            path={getModulePath(LEAGUE_MODULE_NAMES.aboutMe)}
            component={AboutMeRoutes}
            key="about-me"
          />
          <Route
            path={getModulePath(LEAGUE_MODULE_NAMES.onboarding)}
            component={OnboardingRoutesWithProvider}
            key="onboarding"
          />

          <Route component={ErrorPage} />
        </Switch>
      </AuthorizedRoutes>
    </Switch>
  );
}

export default AppRoutes;
